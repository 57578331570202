<template>
  <section class="section partner-companies">
    <h2>{{ $t(`partner_companies_section.${page}`) }}</h2>
    <ScrollSlider
      :items="partnerCompanies"
      class="partner-companies__scroll-slider"
    >
      <template #scroll-slider-item="{ item }">
        <div class="partner-companies__scroll-item">
          <PictureComponent
            :modern-format-image="{
              'desktop-path':
                item.item.image['modern-format-image']['desktop-path'],
              'desktop-path-retina':
                item.item.image['modern-format-image']['desktop-path-retina'],
            }"
            :fallback-format-image="{
              'desktop-path':
                item.item.image['fallback-format-image']['desktop-path'],
              'desktop-path-retina':
                item.item.image['fallback-format-image']['desktop-path-retina'],
            }"
            :alt="item.item.name"
            loading="lazy"
          />
        </div>
        <p>{{ item.item.description }}</p>
        <div class="item__link-wrapper">
          <a href="#" @click.prevent="showFeedback(item.item)">
            {{ $t("Read feedback") }}
          </a>
        </div>
      </template>

      <template #modal>
        <LazyModalsFeedbackModal v-if="mountFeedbackModal" v-model:feedback="feedback" />
      </template>
    </ScrollSlider>
  </section>
</template>

<script lang="ts">
import { PropType } from "vue";
import { User } from "~/types/entities";

import ScrollSlider, {
  IScrollSliderItem,
} from "~/components/slider/ScrollSlider.vue";

export default defineNuxtComponent({
  components: { ScrollSlider },
  props: {
    page: {
      type: String as PropType<User>,
      default: null,
    },
    partnerCompanies: {
      type: Array as PropType<IScrollSliderItem[]>,
      required: true,
    },
  },

  setup() {
    const { gtag } = useGtag()
    const mountFeedbackModal = ref(false)
    const feedback = ref<IScrollSliderItem | null>(null);

    function showFeedback(item: IScrollSliderItem) {
      !mountFeedbackModal.value && (mountFeedbackModal.value = true)
      gtag("event", FeedbackEvents.feedbackModal, {author: item.name})
      feedback.value = item;
    }

    return {
      feedback,
      mountFeedbackModal,
      showFeedback,
    };
  },
});
</script>
